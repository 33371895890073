<div>
  <div>
    <p-table
      #GRN
      [value]="tableData"
      [paginator]="showPaginatorGRNTable"
      [rows]="rows"
      [first]="first"
      [showCurrentPageReport]="true"
      (onPage)="paginate($event)"
      [showFirstLastIcon]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      styleClass="p-datatable-striped"
      [rowsPerPageOptions]="[10, 25, 50]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th
            pTooltip="Entity Name"
            tooltipPosition="top"
            pSortableColumn="EntityName"
            [ngStyle]="{backgroundColor : ap_boolean ? '#313971' : '#a72845'}"
          >
            Entity Name
          </th>
          <th
            pTooltip="Vendor Name"
            tooltipPosition="top"
            pSortableColumn="VendorName"
            [ngStyle]="{backgroundColor : ap_boolean ? '#313971' : '#a72845'}"
          >
            Vendor Name
          </th>
          <th
            pTooltip="PO Number"
            tooltipPosition="top"
            pSortableColumn="PODocumentID"
            [ngStyle]="{backgroundColor : ap_boolean ? '#313971' : '#a72845'}"
          >
            PO Number
          </th>
          <th
            pTooltip="Received GRN No"
            tooltipPosition="top"
            pSortableColumn="docheaderID"
            [ngStyle]="{backgroundColor : ap_boolean ? '#313971' : '#a72845'}"
          >
            Received GRN No
          </th>
          <th
            pTooltip="Received Date"
            tooltipPosition="top"
            pSortableColumn="documentDate"
            [ngStyle]="{backgroundColor : ap_boolean ? '#313971' : '#a72845'}"
          >
            Received Date
          </th>
          <th
            pTooltip="Source"
            tooltipPosition="top"
            pSortableColumn="grn_type"
            [ngStyle]="{backgroundColor : ap_boolean ? '#313971' : '#a72845'}"

          >
            Source
          </th>

          <th [ngStyle]="{backgroundColor : ap_boolean ? '#313971' : '#a72845'}">Actions</th>
        </tr>
        <tr class="sticky_top">
          <td>
            <input
              pInputText
              type="text"
              (input)="
                GRN.filter($event.target.value, 'EntityName', 'contains')
              "
              [value]="GRN.filters['EntityName']?.value"
              placeholder="Search by EntityName"
              class="p-column-filter tableSearchInput"
            />
          </td>
          <td>
            <input
              pInputText
              type="text"
              (input)="
                GRN.filter($event.target.value, 'VendorName', 'contains')
              "
              [value]="GRN.filters['VendorName']?.value"
              placeholder="Search by VendorName"
              class="p-column-filter tableSearchInput"
            />
          </td>
          <td>
            <input
              pInputText
              type="text"
              (input)="
                GRN.filter($event.target.value, 'PODocumentID', 'contains')
              "
              [value]="GRN.filters['PODocumentID']?.value"
              placeholder="Search by PoNumber"
              class="p-column-filter tableSearchInput"
            />
          </td>
          <td>
            <input
              pInputText
              type="text"
              (input)="
                GRN.filter($event.target.value, 'docheaderID', 'contains')
              "
              [value]="GRN.filters['docheaderID']?.value"
              placeholder="Search by GRN no"
              class="p-column-filter tableSearchInput"
            />
          </td>
          <td>
            <input
              pInputText
              type="text"
              (input)="
                GRN.filter($event.target.value, 'documentDate', 'contains')
              "
              [value]="GRN.filters['documentDate']?.value"
              placeholder="Search by ReceivedDate"
              class="p-column-filter tableSearchInput"
            />
          </td>
          <td>
            <input
              pInputText
              type="text"
              (input)="
                GRN.filter($event.target.value, 'grn_type', 'contains')
              "
              [value]="GRN.filters['grn_type']?.value"
              placeholder="Search by Amount"
              class="p-column-filter tableSearchInput"
            />
          </td>

          <td></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-invoiceData>
        <tr>
          <td>{{ invoiceData.EntityName }}</td>
          <td>{{ invoiceData.VendorName }}</td>
          <td>{{ invoiceData.PODocumentID }}</td>
          <td>{{ invoiceData.docheaderID }}</td>
          <td>{{ invoiceData.documentDate + "Z" | date:'dd-MM-yy, h:mm a' }}</td>
          <td>{{ invoiceData.grn_type }}</td>
          <td>
            <i
              class="pi pi-eye f-16 showEye"
              (click)="viewInvoice(invoiceData)"
            ></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5" class="p-t-30">
            <img src="assets/Group 2691.png" width="260" alt="imageUrl" />
            <br />
            No Data found.
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft"> </ng-template>
      <ng-template pTemplate="paginatorright"> </ng-template>
    </p-table>
  </div>
</div>
