import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications-vendor',
  templateUrl: './notifications-vendor.component.html',
  styleUrls: ['./notifications-vendor.component.scss']
})
export class NotificationsVendorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
