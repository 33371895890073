
  <h6 class="font_weight headh6">Account Settings</h6>
  <div class="row bg-white m-0 mt-2">
    <div class="col-md-12 p-3">
      
      <!-- <div>
        <img src="/assets/maskGroup89.png" alt="Logo" />
      </div> -->
      <div class="editBtn" *ngIf="!editable">
        <button class="primary_btn f-12" (click)="onEdit()">
          Edit
        </button>
      </div>
      <form #profile="ngForm" (ngSubmit)="onSave(profile.value)">
        <div class="mt-4">
          <h6 class="f-14">Personal Details</h6>
          <table style="width: 50%">
            <tr class="trHeight">
              <td class="tdLeft">
                <label for="Fname" class="f-13">First name</label
                ><span class="colon">:</span>
              </td>

              <td class="tdRight">
                <input
                  type="text"
                  id="Fname"
                  name="firstName"
                  class="form-control inputProfile"
                  [disabled]="!editable"
                  [(ngModel)]="loginUser.userdetails.firstName"
                  [ngClass]="!editable ? 'onEditFalse' : 'onEditTrue'"
                  required
                />
              </td>
            </tr>
            <tr class="trHeight">
              <td class="tdLeft">
                <label for="Lname" class="f-13">Last name</label>
                <span class="colon">:</span>
              </td>
              <td class="tdRight">
                <input
                  type="text"
                  id="Lname"
                  class="form-control inputProfile"
                  name="lastName"
                  [(ngModel)]="loginUser.userdetails.lastName"
                  [disabled]="!editable"
                  [ngClass]="!editable ? 'onEditFalse' : 'onEditTrue'"
                  required
                />
              </td>
            </tr>
            <tr class="trHeight">
              <td class="tdLeft">
                <label for="username" class="f-13">User name</label
                ><span class="colon">:</span>
              </td>
              <td class="tdRight">
                <input
                  type="text"
                  id="username"
                  name="username"
                  [(ngModel)]="username"
                  disabled
                  class="form-control inputProfile onEditFalse"
                />
              </td>
            </tr>
            <tr class="trHeight">
              <td class="tdLeft">
                <label for="email" class="f-13">Email id</label
                ><span class="colon">:</span>
              </td>
              <td class="tdRight">
                <input
                  type="email"
                  id="email"
                  name="email"
                  [(ngModel)]="loginUser.userdetails.email"
                  disabled
                  class="form-control inputProfile onEditFalse"
                />
              </td>
            </tr>
            <h6 class="f-14 mt-3">App Settings</h6>
            <tr class="trHeight">
              <td class="tdLeft">
                <label for="email" class="f-13">Landing page</label
                ><span class="colon">:</span>
              </td>
              <td class="tdRight">
                <select
                  name="landingPage"
                  ngModel
                  [ngClass]="!editable ? 'onEditFalse' : 'onEditTrue'"
                  [disabled]="!editable"
                  [(ngModel)]="loginUser.userdetails.landingPage"
                  class="form-control inputProfile"
                >
                  <option [value]="item.page" *ngFor="let item of landingPageObj">
                    {{ item.page }}
                  </option>
                </select>
              </td>
            </tr>
            <tr class="trHeight"
              [ngStyle]="{'visibility':vendorInvoiceAccess ? 'visible':'hidden'}">
              <td class="tdLeft">
                <label for="email" class="f-13">Upload option</label
                ><span class="colon">:</span>
              </td>
              <td class="tdRight">
                <select
                  name="uploadOpt"
                  ngModel
                  [ngClass]="!editable ? 'onEditFalse' : 'onEditTrue'"
                  [disabled]="!editable"
                  [(ngModel)]="loginUser.userdetails.uploadOpt"
                  class="form-control inputProfile"
                >
                  <option [value]="item.page" *ngFor="let item of uploadOpt">
                    {{ item.page }}
                  </option>
                </select>
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="editable" class="mt-4">
          <button class="primary_btn f-12 mr-3" [disabled]="profile.invalid">Update</button>
          <button
            class="secondary_btn f-12"
            type="button"
            (click)="onCancel()"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
