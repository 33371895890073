import { Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-document-status',
  templateUrl: './document-status.component.html',
  styleUrls: ['./document-status.component.scss']
})
export class DocumentStatusComponent implements OnInit {

  constructor() {
    
  }

  ngOnInit(): void {
  }

}

