<div style="position: relative;height: 100%;" *ngIf="!isAccountSuccess">
  <div
  class="nav_container"
>
<ul class="nav nav-tabs d-flex  nav_sub">
  <li class="nav-item">
    <a
      [ngClass]="tabName == 'verify' ? 'active':''"
      class="nav-link shade_color f-16 "
      (click)="menuChange('verify')"
      >Email Verify
    </a>
  </li>
  <li class="nav-item">
    <a
    [ngClass]="tabName == 'create' ? 'active':''"
      class="nav-link shade_color f-16"
      (click)="menuChange('create')"
      >Create Account</a
    >
  </li>
</ul>
</div>
  <!-- <div *ngIf="activationBoolean" class="registrationDiv" id="signup"> -->
    <!-- <h1 class="title">Sign Up Form</h1> -->

    <div *ngIf="tabName== 'verify'" class="main_div">
      <form *ngIf="!emailValidationBool">
        <h6 class="f-16">Please Verify your email id</h6>
        <div class="d-flex justify-content-between">
          <div class="inputBox">
            <label for="fName" class="f-12">Email id</label>
            <input
              type="email"
              id="emilid"
              name="userEmail"
              [(ngModel)]="emailID"
              ngModel
              #emailVerify="ngModel"
              class="form-control inputForm f-14"
              pattern="[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
              required
            />
          </div>
          <button
          *ngIf="!otpBool"
          class="primary_btn f-12 mt-3"
          type="submit"
          [disabled]="!emailVerify.valid"
          (click)="sendOTP(emailVerify.value, 'send')"
        >
          Send OTP
        </button>
        </div>
        <div class="otp" *ngIf="showOtpComponent">
          <div class="d-flex justify-content-center mt-2 mb-2">
              <img src="assets/Serina Assets/new_theme/Group 2005.svg" alt="otp" width="360" height="220" >
          </div>
          <div class="f-16 primary_clr">Please enter OTP</div>
          <ng-otp-input
            #ngOtpInput
            (onInputChange)="onOtpChange($event)"
            [config]="config"
          >
          </ng-otp-input>
          <div class="f-12 resend-div">
            Didn't receive OTP?
            <span *ngIf="timer != 0">Please wait <span class="text_color">{{ timer }}sec</span> </span>
            <a
              class="f-13"
              *ngIf="timer == 0"
              (click)="sendOTP(emailVerify.value, 'resend')"
              >Resend</a
            >
          </div>
        </div>
  
        <div class="btns">
  
  
          <button
            *ngIf="otpBool"
            class="primary_btn f-12 mr-3"
            type="submit"
            [disabled]="otp?.length < 6"
            (click)="verifyOTP()"
          >
            Verify OTP
          </button>
        </div>
      </form>
    </div>

    <div  *ngIf="tabName== 'create' && isEmailVerified" class="main_div">
      <h6 class="f-16">Please select below options</h6>
      <div class="acc_options">
        <div>
          <img src="assets/Serina Assets/new_theme/Group 2064.svg" alt="ERP" width="180" height="120">
          <label class="f-14"><input type="radio" class="mr-2" name="account" (change)="onSelectType('ERP')">Account in ERP</label>
        </div>

        <div>
          <img src="assets/Serina Assets/new_theme/Group 2067.svg" alt="Serina" width="180" height="120">
          <label class="f-14"><input type="radio" class="mr-2" name="account" (change)="onSelectType('serina')">Account in Serina</label>
        </div>
      </div>
      <form
      class="mt-2"
      *ngIf="emailValidationBool"
      [formGroup]="registrationForm"
      (ngSubmit)="savePasswordforNewuser()"
    >
      <h6 class="f-16">Please type Vendor Credentials</h6>
      <div class="reg_div">
        <div>
          <div class="inputBox">
            <label for="userName">User Name</label>
            <input
              type="text"
              id="userName"
              formControlName="userName"
              class="form-control inputForm"
              autocomplete="off"
              (keyup)="userCheck($event.target.value)"
            />
          </div>
          <div
            *ngIf="registrationForm.controls['userName'].invalid && (registrationForm.controls['userName'].dirty || registrationForm.controls['userName'].touched)"
            class="f-12 alertDiv"
          >
            <div *ngIf="registrationForm.controls['userName'].errors?.required">Username is required.</div>
            <div *ngIf="registrationForm.controls['userName'].errors?.minlength">
              Username must be at least 6 characters long.
            </div>
            <!-- <div *ngIf="user_name.errors?.['forbiddenName']">Name cannot be Bob.</div> -->
          </div>
          <div *ngIf="usernameField" class="f-12 alertDiv">
            Username is already taken, please try other one.
          </div>
        </div>
        <div class="inputBox" *ngIf="accountType == 'serina'">
          <label for="userName">Vendor Name</label>
          <input
            type="text"
            id="vendorName"
            formControlName="vendorName"
            class="form-control inputForm"
            autocomplete="off"
          />
        </div>
        <div class="inputBox">
          <label for="fName">Email</label>
          <input
            type="email"
            id="emil"
            formControlName="emailId"
            class="form-control inputForm"
            pattern="[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
          />
        </div>
        <div class="inputBox">
          <label for="fName">First Name</label>
          <input
            type="text"
            id="fName"
            formControlName="firstName"
            class="form-control inputForm"
          />
        </div>
        <div class="inputBox">
          <label for="lName">Last Name</label>
          <input
            type="text"
            id="lName"
            formControlName="lastName"
            class="form-control inputForm"
          />
        </div>
        <div class="inputBox">
          <div class="form-group mb-0">
            <label> Set Password</label>
            <div class="input-group">
              <!-- Change input type to be dynamic -->
              <!-- <input
                          [type]="fieldTextType ? 'text' : 'password'"
                          class="form-control inputForm w-260"
                          formControlName="password"
                        /> -->
              <input
                [type]="fieldTextType ? 'text' : 'password'"
                name="newpassword"
                formControlName="password"
                class="f-13 form-control"
                minlength="8"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()/?_+\-=\[\]{};':\\|,.]).{8,}"
                (keyup)="checkPattren($event.target.value)"
                autocomplete="off"
                required
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-eye-slash': !fieldTextType,
                      'fa-eye': fieldTextType
                    }"
                    (click)="toggleFieldTextType()"
                  ></i>
                </span>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              registrationForm.get('password').invalid &&
              (registrationForm.get('password').touched ||
                registrationForm.get('password').dirty)
            "
            class="alertDiv"
          >
            <div [hidden]="!registrationForm.get('password').errors?.pattern">
              <ul class="pass_pattern">
                <li class="checkColor">At least 1 number required</li>
                <li class="checkColor">At least 1 lowercase letter required</li>
                <li class="checkColor">At least 1 uppercase letter required</li>
                <li class="checkColor">At least 1 special character required</li>
                <li class="checkColor">Minimum 8 characters required</li>
              </ul>
            </div>
            <div [hidden]="!registrationForm.get('password').errors.required">
              **Required field
            </div>
          </div>
          <!-- <label for="setPass">Set Password</label>
                  <div class="input-group">
                  <input [type]="fieldTextType ? 'text' : 'password'" id="setPass" formControlName ="password" class="form-control inputForm">
  
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fa fa-eye-slash"></i>
                        </span>
                     </div>
                  </div>
                  
                  <div>
                      <i class="fa fa-eye eye-show" [ngClass]="{
                          'fa-eye-slash': !fieldTextType,
                          'fa-eye': fieldTextType
                        }" (click)="toggleFieldTextType()"></i>
                  </div> -->
          <!-- <div class="error-text" *ngIf="registrationForm.get('password').invalid && (registrationForm.get('password').touched || registrationForm.get('password').dirty)">
  
                      <div *ngIf="registrationForm.get('password').hasError('required')">
                          password is required
                      </div>
              
                      <div *ngIf="registrationForm.get('password').errors.minlength">
                          Password must be at least 8 characters
                      </div>
              
                      <div class="error-text" *ngIf="registrationForm.get('password').hasError('passwordStrength')">
                          {{registrationForm.get('password').errors['passwordStrength']}}
                      </div>
                  </div> -->
        </div>
  
        <div class="inputBox"> 
          <label for="confirmPass">Confirm Password</label>
          <input
            type="password"
            id="confirmPass"
            formControlName="reEnterPassword"
            (keyup)="confirmPassword($event.target.value)"
            class="form-control inputForm"
          />
          <div *ngIf="errorDivBoolean" class="alertDiv">
            Please enter same password
          </div>
        </div>
      </div>
      <div class="f-12 text_color" *ngIf="accountType == 'ERP'">
        Note : Once you Submit details please login to the account to proceed
      </div>
      <div class="f-12">
        <!-- <label for="terms">
          <input type="checkbox" name="terms" id="terms">
          <span>Please read the terms and conditions</span>
        </label> -->
        By clicking {{ accountType == 'serina'? 'Sign Up':'Submit'}}, you agree to our <a href="static-content/DPA" target="_blank">Data Processing Agreement ("DPA")</a> and <a href="static-content/privacy-policy" target="_blank"> Privacy Policy</a>. You may receive Email notifications from us.
      </div>

      <div class="btns">
        <button
          style="margin-right: 30px;"
          class="primary_btn f-12"
          type="submit"
          [disabled]="!registrationForm.valid || errorDivBoolean"
        >
          {{ accountType == 'serina'? 'Sign Up':'Submit'}}
        </button>
      </div>
    </form>
    </div>

    <div *ngIf="tabName== 'create' && !isEmailVerified" class="f-14 d-flex justify-content-center p-4">
      Please Verify your email Id first.
    </div>
  <!-- </div> -->
  <!-- <div *ngIf="!activationBoolean" class="expireText thankyou">
    <div class="confetti">
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
      <div class="confetti-piece"></div>
    </div>
        <div>
        Thank you for signup with us, please contact the admin to approve your account in the Serina.
        </div>
    </div> -->
</div>

<div *ngIf="isAccountSuccess" class="successDiv">
  <div class="d-flex justify-content-center mt-2 mb-2">
    <img src="assets/Serina Assets/new_theme/Group 2763.svg" alt="otp" width="420" height="220" >
  </div>
  <div class="f-20">Congratulation!! </div>
  <div class="f-16">You've made it. Let's celebrate</div>
  <div class="f-14 shade_color mt-2" *ngIf="accountType == 'serina'">
    Please wait until the admin approve you request. we will notify you once done.
  </div>
  <div class="f-14 shade_color mt-2" *ngIf="accountType == 'ERP'">
    Please login to your account and add the details.
  </div>
  <button class="f-12 primary_btn mt-4" (click)="closeDialog()">Login</button>
</div>

<p-toast></p-toast>
