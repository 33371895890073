<div class="text-center center_absolute p-3">
    <h5 class="font_weight">Change Password</h5>
  </div>
  <div mat-dialog-content>

    <form
      #changePassword="ngForm"
      (ngSubmit)="savePassword(changePassword.value)"
      class="formDiv pl-3 pr-3"
    >
      
      <label class="f-13">New Password </label>
      <input
        type="text"
        name="newpassword"
        ngModel
        #newPass="ngModel"
        class="f-13 form-control"
        minlength="8"
        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':\\|,.]).{8,}"
        (keyup)="checkPattren(newPass.value)"
        autocomplete="off"
        required
      />
      <div
      *ngIf="newPass.invalid && (newPass.touched || newPass.dirty)"
      class="alertDiv"
    >
      <div [hidden]="!newPass.errors?.pattern">
        <!-- Password must contain numbers, lowercase letters, uppercase
        letters special characters and minimum 8 characters. -->
        <ul>
          <li class="checkColor">At least 1 number required</li>
          <li class="checkColor">At least 1 lowercase letter required</li>
          <li class="checkColor">At least 1 uppercase letter required</li>
          <li class="checkColor">At least 1 special character required</li>
          <li class="checkColor">Minimum 8 characters required</li>
        </ul>
      </div>
      <div [hidden]="!newPass.errors.required">**Required field</div>
    </div>
      <label class="f-13">Confirm Password </label>
      <input
        type="password"
        name="confirmPassword"
        ngModel
        #confirmPass="ngModel"
        class="form-control f-13"
        (keyup)="testPassword(newPass.value,confirmPass.value)"
        autocomplete="off"
        required
      />
      <div class="alert alert-danger f-12" *ngIf="passwordMatchBoolean">
        Please enter the same password.
      </div>

      <div class="mt-4 d-flex justify-content-center mb-3">
        <button class="secondary_btn f-12 mr-3" type="button" mat-dialog-close>
          Cancel
        </button>
        <button
          class="primary_btn f-12"
          type="submit"
          [disabled]="changePassword.invalid"
        >
          Save
        </button>
        
      </div>
    </form>
  </div>
