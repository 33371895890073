
import { Component } from '@angular/core';
@Component({
  selector: 'app-action-center-vendor',
  templateUrl: './action-center-vendor.component.html',
  styleUrls: ['./action-center-vendor.component.scss']
})
export class ActionCenterVendorComponent {

}
