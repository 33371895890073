import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-invoice-exceptions',
  templateUrl: './service-invoice-exceptions.component.html',
  styleUrls: ['./service-invoice-exceptions.component.scss']
})
export class ServiceInvoiceExceptionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
