<h6 class="hd6"></h6>

<div
  class="nav_pane_width1 d-flex justify-content-between flex-wrap inv_nav_container"
>
  <ul class="nav nav-pills">
    <li class="nav-item mr-1">
      <a
        class="nav-link f-14"
        [class.active-pill]="viewType == 'Process'"
        (click)="choosepageTab('Process')"
      >
        Process Metrics</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-14"
        [class.active-pill]="viewType == 'detailed'"
        (click)="choosepageTab('detailed')"
      >
        Detailed Reports</a
      >
    </li>
  </ul>
</div>
<div [ngSwitch]="viewType" style="padding-top: 8px">
  <!-- Process invoices Tab -->
  <div *ngSwitchCase="'Process'">
    <process-metrics></process-metrics>
  </div>

  <!-- detailed invoices Tab -->
  <div *ngSwitchCase="'detailed'" class="tableDataDiv">
    <detailed-reports></detailed-reports>
  </div>
</div>
