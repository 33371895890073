<!-- <mat-drawer-container class="example-container" autosize style="display: none;">
  <mat-drawer #drawer class="example-sidenav sideBar d-flex" [mode]="sidebarMode" [opened]="isnotTablet">
    <div class="d_desk1">
      <div class="logotitle">
        <div class="logoPlaceholder">
          <img
            class="logo_img"
            src="assets/serinaLogo01.png"
            alt="logo"
            width="130"
            height="42"
          />
        </div>
        <div class="placeLogo">
          <span class="portalName">Customer Portal</span>
        </div>
      </div>
      <div class="logotitle" *ngIf="!ap_boolean">
        <img src="assets/Arenalogo.png" alt="" style="width:100%">
      </div>
      <div class="routeLinks">
        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Dashboard"
          [routerLink]="'home'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            home
          </span>
          Dashboard
        </div>

        <div
          *ngIf="uploadPermissionBoolean"
          class="navLink"
          routerLinkActive="active"
          data-text="Upload"
          [routerLink]="'uploadInvoices'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            upload_file
          </span>
          Upload
        </div>
        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Exceptions"
          [routerLink]="exceptionRoute"
          (click)="showInnerException()"
          *ngIf="excpetionPageAccess"
        >
          <i
            class="fa fa-pencil-square f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>

          Exceptions
        </div>
        <ul *ngIf="openBooleanException" class="showHide f-13">
          <li
            class="navLink"
            id="ex_sub"
            *ngIf="vendorInvoiceAccess"
            [routerLink]="'ExceptionManagement'"
            [ngClass]="
              router.url == '/customer/ExceptionManagement'
                ? 'active'
                : 'navLink'
            "
          >
            {{cust_type}} Based
          </li>
          <li
            class="navLink"
            id="ex_sub"
            *ngIf="serviceInvoiceAccess"
            [routerLink]="'ExceptionManagement/Service_ExceptionManagement'"
            [ngClass]="
              router.url ==
              '/customer/ExceptionManagement/Service_ExceptionManagement'
                ? 'active'
                : 'navLink'
            "
          >
            Service Based OCR
          </li>
        </ul>

        <div
          *ngIf="financeapproveDisplayBoolean && vendorInvoiceAccess && ap_boolean"
          class="navLink"
          routerLinkActive="active"
          data-text="Approval"
          [routerLink]="'approvals'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            verified
          </span>
          Approval
        </div>
        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Create GRN"
          [routerLink]="'Create_GRN_inv_list'"
          *ngIf="GRNCreationAccess && GRNPageAccess && vendorInvoiceAccess && ap_boolean"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            verified
          </span>
          Create GRN
        </div>

        <div
          class="navLink"
          routerLinkActive="active"
          [ngClass]="router.url.includes('invoice')?'active':''"
          data-text="Document Status"
          (click)="doc_status_route()"
        >
          <span class="material-icons f-18 ml-r-12" [ngClass]="router.url.includes('invoice')?'ml-9':''">
            receipt
          </span>
          Document Status
        </div>

        <div
        class="navLink"
        routerLinkActive="active"
        data-text="Summary"
        [routerLink]="'documentSummary'"
      >
        <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
          receipt
        </span>
        Summary
      </div>

        
        <div
          class="navLink"
          routerLinkActive="active"
          [data-text]="cust_type"
          [routerLink]="'vendor'"
          *ngIf="vendorInvoiceAccess && vendor_SP_PageAccess"
          (click)="sideMenuVendor()"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            business_center
          </span>
          {{cust_type}}
        </div>
        <ul *ngIf="openBooleanVendor" class="showHide f-13">
          <li
            class="navLink"
            id="ex_sub"
            [routerLink]="'vendor'"
            [ngClass]="router.url == '/customer/vendor' ? 'active' : 'navLink'"
          >
            List
          </li>
          <li
            *ngIf="ap_boolean"
            class="navLink"
            id="ex_sub"
            [routerLink]="'vendor/item_master'"
            [ngClass]="
              router.url.includes('item_master') ? 'active' : 'navLink'
            "
          >
            Item Master
          </li>
        </ul>

        <div
          class="navLink"
          [routerLink]="'serviceProvider'"
          routerLinkActive="active"
          data-text="Service Provider"
          (click)="showInner()"
          *ngIf="serviceInvoiceAccess && vendor_SP_PageAccess"
        >
          <i
            class="fa fa-user-secret f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Service Provider
        </div>
        <ul *ngIf="openBoolean" class="showHide f-13">
          <li
            class="navLink"
            id="sp_sub"
            [routerLink]="'serviceProvider'"
            [ngClass]="
              router.url == '/customer/serviceProvider' ? 'active' : 'navLink'
            "
          >
            List
          </li>
          <li
            class="navLink"
            id="sp_sub"
            [routerLink]="'serviceProvider/UtilityInvokeBatch'"
            [ngClass]="
              router.url == '/customer/serviceProvider/UtilityInvokeBatch'
                ? 'active'
                : 'navLink'
            "
          >
            Utility Invoice Batch
          </li>
          
          <li
            *ngIf="isAGIUser"
            class="navLink"
            id="sp_sub"
            [routerLink]="'serviceProvider/EtisalatCostAllocation'"
            [ngClass]="
              router.url == '/customer/serviceProvider/EtisalatCostAllocation'
                ? 'active'
                : 'navLink'
            "
          >
            Upload Etisalat
          </li>
        </ul>

        <div
          *ngIf="addUsersBoolean"
          class="navLink"
          data-text="Roles"
          routerLinkActive="active"
          [routerLink]="'roles'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            manage_accounts
          </span>
          Roles
        </div>

        <div *ngIf="!isDesktop" class="navLink" data-text="Change Password" (click)="openDialog()">
          <i
            class="fa fa-lock f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Change Password
        </div>

        <div
          *ngIf="!isDesktop"
          class="navLink"
          data-text="Logout"
          (click)="logout_confirmation()"
        >
          <i
            class="fa fa-sign-out f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Logout
        </div>
      </div>

      <div class="copy">
        Copyrights <span class="cpr f-14">&#169;</span> 2024
      </div>
    </div>
  </mat-drawer>

  <div class="example-sidenav-content">
    <div class="bodyContent">
      <div class="d_desk1">
        <i
          class="fa fa-bars menu_icon f-16"
          aria-hidden="true"
          (click)="drawer.toggle()"
        ></i>
        <div class="f-right d_desk">
          <span class="userName last_log f-11">
            <span>Last login :&nbsp; </span>
            {{ last_login1 | date: "medium" }} <span style="color: crimson;">{{timezone}}</span> &nbsp; &nbsp;
          </span>
          <span
            class="b-right"
            style="padding-right: 14px; cursor: pointer"
            [routerLink]="'notifications'"
          >
            <i
              class="pi pi-bell p-mr-4 p-text-secondary f-18"
              [value]="numberOfNotify"
              pBadge
            ></i
          ></span>

          <span class="ml-2 dropdown">
            <i class="fa fa-user-circle-o profileIcon" aria-hidden="true"></i>
          </span>
          <span class="b-left-sky clr userName"
            >{{ userDetails?.userdetails?.firstName }}
          </span>
          <span (clickOutside)="onClickedOutside($event)">
            <mat-icon
              class="sign_out dropdown"
              svgIcon="logout"
              title="Sign Out"
              (click)="isActive()"
            ></mat-icon>
            <div
              [ngStyle]="{ display: showLogout ? 'block' : 'none' }"
              class="dropdown-content"
            >
              <a *ngIf="bothDocBoolean">Switch to {{switchtext}} 
                <span class="portal">
                <label class="switch">
                  <input
                    type="checkbox"
                    [checked]="ap_boolean"
                    (change)="portalChange()"
                  />
                  <span class="slider round"></span>
                </label>
              </span></a>
              <a (click)="openDialog()">Change Password</a>
              <a routerLink="profile">Profile</a>
              <a (click)="logout_confirmation()">Logout</a>
            </div>
          </span>
        </div>
      </div>

      <nav class="d_mobile1">
         <div class="logotitle">
          <div class="logoPlaceholder">
            <img
              class="logo_img"
              src="assets/serina.png"
              alt="logo"
              width="35"
              height="35"
            />
          </div>
          <div class="placeLogo">
            Serina
            <span class="portalName">Customer Portal</span>
          </div>
        </div>
        <div class="f-right mr-3 mob_menu" (click)="onClickMenu()">
          <input type="checkbox" name="toggle-nav" />
          <label for="toggle-nav">
            <span class="menu-icon"></span>
          </label>
        </div>
        <section class="pos_rel" *ngIf="menubarBoolean">
          <div class="routeLinks route_mobile">
            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Dashboard"
              [routerLink]="'home'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                home
              </span>
              Dashboard
            </div>

            <div
              *ngIf="uploadPermissionBoolean &&vendorInvoiceAccess"
              class="navLink"
              routerLinkActive="active"
              data-text="Upload"
              [routerLink]="'uploadInvoices'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                upload_file
              </span>
              Upload
            </div>
            <div
            class="navLink"
            routerLinkActive="active"
            data-text="Exceptions"
            [routerLink]="exceptionRoute"
            (click)="showInnerException()"
            *ngIf="excpetionPageAccess"
          >
            <i
              class="fa fa-pencil-square-o f-18 ml-r-12"
              aria-hidden="true"
              routerLinkActive="ml-9"
            ></i>

            Exceptions
          </div>
          <ul *ngIf="openBooleanException" class="showHide f-13">
            <li
              class="navLink"
              *ngIf="vendorInvoiceAccess"
              id="ex_sub"
              [routerLink]="'ExceptionManagement'"
              [ngClass]="
                router.url == '/customer/ExceptionManagement'
                  ? 'active'
                  : 'navLink'
              "
            >
              {{cust_type}} Based
            </li>
            <li
              class="navLink"
              id="ex_sub"
              *ngIf="serviceInvoiceAccess"
              [routerLink]="'ExceptionManagement/Service_ExceptionManagement'"
              [ngClass]="
                router.url ==
                '/customer/ExceptionManagement/Service_ExceptionManagement'
                  ? 'active'
                  : 'navLink'
              "
            >
              Service Based OCR
            </li>
          </ul>

          <div
            *ngIf="financeapproveDisplayBoolean && vendorInvoiceAccess && ap_boolean"
            class="navLink"
            routerLinkActive="active"
            data-text="Approval"
            [routerLink]="'approvals'"
          >
            <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
              verified
            </span>
            Approval
          </div>
          <div
            class="navLink"
            routerLinkActive="active"
            data-text="Create GRN"
            [routerLink]="'Create_GRN_inv_list'"
            *ngIf="GRNCreationAccess && GRNPageAccess && vendorInvoiceAccess && ap_boolean"
          >
            <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
              verified
            </span>
            Create GRN
          </div>
      
            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Document Status"
              [routerLink]="'invoice'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                receipt
              </span>
              Document Status
            </div>

            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Summary"
              [routerLink]="'documentSummary'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                receipt
              </span>
              Summary
            </div>

            <div
              class="navLink"
              routerLinkActive="active"
              [data-text]="cust_type"
              [routerLink]="'vendor'"
              *ngIf="vendorInvoiceAccess && vendor_SP_PageAccess"
              (click)="sideMenuVendor()"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                business_center
              </span>
              {{cust_type}}
            </div>
            <ul *ngIf="openBooleanVendor" class="showHide f-13">
              <li
                class="navLink"
                id="ex_sub"
                [routerLink]="'vendor'"
                [ngClass]="
                  router.url == '/customer/vendor' ? 'active' : 'navLink'
                "
              >
                List
              </li>
              <li
                class="navLink"
                id="ex_sub"
                [routerLink]="'vendor/item_master'"
                [ngClass]="
                  router.url.includes('item_master') ? 'active' : 'navLink'
                "
              >
                Item Master
              </li>
            </ul>

            <div
              class="navLink"
              [routerLink]="'serviceProvider'"
              routerLinkActive="active"
              data-text="Service Provider"
              (click)="showInner()"
              *ngIf="serviceInvoiceAccess && vendor_SP_PageAccess"
            >
              <i
                class="fa fa-user-secret f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Service Provider
            </div>
            <ul *ngIf="openBoolean" class="showHide f-13">
              <li
                class="navLink"
                id="sp_sub"
                [routerLink]="'serviceProvider'"
                [ngClass]="
                  router.url == '/customer/serviceProvider'
                    ? 'active'
                    : 'navLink'
                "
              >
                List
              </li>
              <li
                class="navLink"
                id="sp_sub"
                [routerLink]="'serviceProvider/UtilityInvokeBatch'"
                [ngClass]="
                  router.url == '/customer/serviceProvider/UtilityInvokeBatch'
                    ? 'active'
                    : 'navLink'
                "
              >
                Utility Invoice Batch
              </li>
            <li class="navLink" id="sp_sub" [routerLink]="'serviceProvider/summary'"
                          [ngClass]="router.url == '/customer/serviceProvider/summary'?'active':'navLink'">Summary </li> -->
<!-- <li class="navLink" id="sp_sub" [routerLink]="'serviceProvider/invoices'"
                          [ngClass]="router.url == '/customer/serviceProvider/invoices'?'active':'navLink'">invoices</li> -->
<!-- <li
                class="navLink"
                id="sp_sub"
                [routerLink]="'serviceProvider/bulk_upload'"
                [ngClass]="
                  router.url == '/customer/serviceProvider/bulk_upload'
                    ? 'active'
                    : 'navLink'
                "
              >
                Bulk Upload
              </li> 
              <li
                *ngIf="isAGIUser"
                class="navLink"
                id="sp_sub"
                [routerLink]="'serviceProvider/EtisalatCostAllocation'"
                [ngClass]="
                  router.url ==
                  '/customer/serviceProvider/EtisalatCostAllocation'
                    ? 'active'
                    : 'navLink'
                "
              >
                Upload Etisalat
              </li>
            </ul>

            <div
              *ngIf="addUsersBoolean"
              class="navLink"
              data-text="Roles"
              routerLinkActive="active"
              [routerLink]="'roles'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                manage_accounts
              </span>
              Roles
            </div>
            <div
              class="navLink"
              data-text="Change Password"
              (click)="openDialog()"
            >
              <i
                class="fa fa-lock f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Change Password
            </div>

            <div
              class="navLink"
              data-text="Logout"
              (click)="logout_confirmation()"
            >
              <i
                class="fa fa-sign-out f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Logout
            </div>
          </div>
        </section>
      </nav>
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-drawer-container> -->

<div class="layout">
  <nav
    class="desktop d-flex justify-content-between"
    [ngClass]="
    ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name) ? 'cenomiBg' : ''
    "
  >
    <div class="d-flex" style="gap: 64px">
      <div>
        <img
        *ngIf="dataStoreService?.configData?.client_name != 'AGI'"
          [src]="logoSrc"
          alt="Serina logo"
          [ngClass]="
          ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'otherBrand'
              : 'Serina'
          "
        />
        <img
          *ngIf="dataStoreService?.configData?.client_name == 'AGI'"
          [src]="logoSrc"
          alt="Serina logo"
          class="AGI"
        />
      </div>
      <!-- <div  *ngIf="dataStoreService.configData.client_name != 'Cenomi'">
        <img src="assets/Serina Assets/new_theme/logo.png" alt="Serina logo" class="Serina" />
      </div> -->
      <div class="navLinks d-flex justify-content-between" *ngIf="isDesktop">
        <div
          class="nav_Link f-14"
          [ngClass]="
          ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'cenomiNavlink'
              : ''
          "
          [routerLink]="'home'"
          routerLinkActive="active_cls"
          (click)="navClick()"
        >
          Dashboard
        </div>
        <div
          class="nav_Link f-14"
          [ngClass]="
            ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'cenomiNavlink'
              : ''
          "
          [routerLink]="'uploadInvoices'"
          routerLinkActive="active_cls"
          *ngIf="uploadPermissionBoolean"
          (click)="navClick()"
        >
          Upload
        </div>

        <div style="position: relative">
          <div
            class="nav_Link f-14"
            [ngClass]="{
              active_cls: router.url.includes('ExceptionManagement'),
              cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
            }"
            (click)="exceptionDrop()"
            [routerLink]="vendorInvoiceAccess && !serviceInvoiceAccess ? 'ExceptionManagement' : ((!vendorInvoiceAccess && serviceInvoiceAccess) ? 'ExceptionManagement/Service_ExceptionManagement':null)"
            *ngIf="excpetionPageAccess"
          >
            Exceptions
            <span
              class="material-icons more_pos"
              *ngIf="vendorInvoiceAccess && serviceInvoiceAccess && !isOpen"
            >
              expand_more
            </span>
            <span
              class="material-icons more_pos"
              *ngIf="vendorInvoiceAccess && serviceInvoiceAccess && isOpen"
            >
              expand_less
            </span>
          </div>
          <div
            [ngStyle]="{ display: isOpen ? 'block' : 'none' }"
            class="dropdown-content"
          >
            <a
              [routerLink]="'ExceptionManagement'"
              routerLinkActive="active_sub_cls"
              (click)="exceptionMenu('vendor', 'exc')"
              >Vendor</a
            >
            <a
              [routerLink]="'ExceptionManagement/Service_ExceptionManagement'"
              routerLinkActive="active_sub_cls"
              (click)="exceptionMenu('service', 'exc')"
              >Service</a
            >
            <a
              [routerLink]="'ExceptionManagement/approvalPending'"
              routerLinkActive="active_sub_cls"
              *ngIf="financeapproveDisplayBoolean"
              (click)="exceptionMenu('service', 'exc')"
              >Approval Pending</a
            >
          </div>
        </div>
        <div
          class="nav_Link f-14"
          [ngClass]="
            ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'cenomiNavlink'
              : ''
          "
          [routerLink]="'Create_GRN_inv_list'"
          routerLinkActive="active_cls"
          *ngIf="GRNCreationAccess && GRNPageAccess && vendorInvoiceAccess"
          (click)="navClick()"
        >
          Create GRN
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]=" vendorInvoiceAccess ? 'invoice/allInvoices':'invoice/ServiceInvoices'"
          (click)="doc_status_route()"
          *ngIf="show_document_status"
          [ngClass]="{
            active_cls: router.url.includes('invoice'),
            cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          }"
          routerLinkActive="active_cls"
        >
          Document Status
        </div>
        <div
          style="position: relative"
          *ngIf="
            financeapproveDisplayBoolean &&
            vendorInvoiceAccess &&
            approveBoolean
          "
        >
          <div
            class="nav_Link f-14"
            (click)="approvalDropdown()"
            [ngClass]="{
              active_cls: router.url.includes('approvals'),
              cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
            }"
          >
            Approvals
            <span
              class="material-icons more_pos"
              *ngIf="
                vendorInvoiceAccess &&
                serviceInvoiceAccess &&
                !isOpen_apr &&
                serviceApprovalsEnabled
              "
            >
              expand_more
            </span>
            <span
              class="material-icons more_pos"
              *ngIf="
                vendorInvoiceAccess &&
                serviceInvoiceAccess &&
                isOpen_apr &&
                serviceApprovalsEnabled
              "
            >
              expand_less
            </span>
          </div>
          <div
            [ngStyle]="{ display: isOpen_apr ? 'block' : 'none' }"
            class="dropdown-content"
          >
            <a
              [routerLink]="'approvals'"
              routerLinkActive="active_sub_cls"
              (click)="exceptionMenu('vendor', 'apr')"
              >Vendor</a
            >
            <a
              [routerLink]="'approvals/ServiceInvoices'"
              routerLinkActive="active_sub_cls"
              (click)="exceptionMenu('service', 'apr')"
              >Service</a
            >
          </div>
        </div>

        <div
          class="nav_Link f-14"
          [ngClass]="
            ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'cenomiNavlink'
              : ''
          "
          [routerLink]="'GRN_approvals'"
          routerLinkActive="active_cls"
          *ngIf="GRNApprovalAccess"
          (click)="navClick()"
        >
          GRN Approvals
        </div>
        <div
          style="position: relative"
          (clickOutside)="onClickedOutside($event, 'more')"
        >
          <div
            class="nav_Link f-14"
            (click)="more_routes()"
            [ngClass]="{
              active_cls:
                router.url.includes('/customer/vendor/') ||
                router.url.includes('serviceProvider') ||
                router.url.includes('documentSummary') ||
                router.url.includes('roles') ||
                router.url.includes('service_batch_trigger') ||
                router.url.includes('bulkUploadService') ||
                router.url.includes('ERP_approvals') ||
                router.url.includes('settings'),
              cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
            }"
          >
            {{ more_text }}
            <span class="material-icons more_pos">
              {{ more_icon }}
            </span>
          </div>
          <div
            [ngStyle]="{
              display: more_icon == 'expand_less' ? 'block' : 'none'
            }"
            class="dropdown-content"
          >
            <a
              [routerLink]="'documentSummary'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('Summary')"
              >Summary</a
            >
            <a
              [routerLink]="supplier_route"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange(supplier_names)"
              *ngIf="vendor_SP_PageAccess"
              >{{ supplier_names }}</a
            >
            <a
              [routerLink]="'roles'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('Roles')"
              *ngIf="addUsersBoolean"
              >Roles</a
            >
            <!-- <a
              [routerLink]="'ERP_approvals'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('ERP Approvals')"
              *ngIf="addUsersBoolean"
              >ERP Approvals</a
            > -->
            <a
              [routerLink]="'bulkUploadService'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('Service Bulk upload/ Batch trigger')"
              *ngIf="serviceInvoiceAccess && serviceTriggerBoolean"
              >Service Bulk upload/ Batch trigger</a
            >
            <a
              [routerLink]="'settings'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('Settings')"
              >Settings</a
            >
            <!-- <a
              [routerLink]="'service_batch_trigger'"
              routerLinkActive="active_sub_cls"
              (click)="onMenuChange('Service batch trigger')"
              *ngIf="serviceInvoiceAccess"
              >Service batch trigger</a
            > -->
          </div>
        </div>

        <!-- <div >
          <div
          class="nav_Link f-14"
          [routerLink]="'vendor'"
          routerLinkActive="active_cls"
          *ngIf="vendor_SP_PageAccess"
        >
          Vendors
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]="'serviceProvider'"
          routerLinkActive="active_cls"
          *ngIf="vendor_SP_PageAccess"
        >
          Service Providers
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]="'roles'"
          routerLinkActive="active_cls"
          *ngIf="addUsersBoolean"
        >
          Roles
        </div>
        </div> -->
      </div>
    </div>

    <div
      class="d-flex align-items-center"
      [ngStyle]="{ marginRight: !isDesktop ? '40px' : '' }"
    >
      <div
        class="user_d f-13"
        *ngIf="
          (router.url.includes('ExceptionManagement') ||
            router.url.includes('invoice') ||
            router.url.includes('documentSummary') ||
            router.url.includes('GRN_approvals') ||
            router.url.includes('approvals') ||
            router.url.includes('bulkUploadService') ||
            router.url.includes('Create_GRN_inv_list')) &&
          isDesktop
        "
        [ngClass]="
            ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
              ? 'cenomiNavlink'
              : ''
          "
      >
        Card
        <span class="portal">
          <label class="switch">
            <input
              type="checkbox"
              [checked]="isTableView"
              (change)="onChangeUI($event.target.checked)"
            />
            <span class="slider round"></span>
          </label>
        </span>
        Table
      </div>
      <div class="short_name f-12">{{ name_short }}</div>
      <div
        class="f-12 mr-1"
        [ngClass]="
          ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
            ? 'cenomiNavlink'
            : ''
        "
        *ngIf="!isMobile"
      >
        {{ userDetails.userdetails.firstName }}
      </div>
      <div (clickOutside)="onClickedOutside($event, 'logout')" *ngIf="!isMobile">
        <!-- <mat-icon
          class="sign_out dropdown"
          svgIcon="logout"
          title="Sign Out"
          (click)="isActive()"
        ></mat-icon> -->
        <span class="material-icons sign_out dropdown"  
          title="Sign Out"
          (click)="isActive()">
          logout
          </span>
        <div
          [ngStyle]="{ display: showLogout ? 'block' : 'none' }"
          class="dropdown-content"
        >
          <a (click)="openDialog()">Change Password</a>
          <a routerLink="profile">Profile</a>
          <a (click)="confirm_pop()">Logout</a>
        </div>
      </div>
    </div>
    <div *ngIf="!isDesktop" class="menuIcon" (click)="openMenu()">
      <span class="material-icons">
        {{ isMenuOpen ? "close" : "menu" }}
      </span>
    </div>
  </nav>
  <div *ngIf="isMenuOpen" class="mobile_menu">
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'home'"
      routerLinkActive="active_cls"
    >
      Dashboard
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'uploadInvoices'"
      routerLinkActive="active_cls"
      *ngIf="uploadPermissionBoolean"
    >
      Upload
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="{
        active_cls: router.url.includes('ExceptionManagement'),
        cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
      }"
      (click)="exceptionDrop()"
      [routerLink]="vendorInvoiceAccess && !serviceInvoiceAccess ? 'ExceptionManagement' : ((!vendorInvoiceAccess && serviceInvoiceAccess) ? 'ExceptionManagement/Service_ExceptionManagement':null)"
      *ngIf="excpetionPageAccess"
    >
      <details *ngIf="vendorInvoiceAccess && serviceInvoiceAccess">
        <summary>Exceptions</summary>
        <div class="d-flex flex-column">
          <a
            [routerLink]="'ExceptionManagement'"
            routerLinkActive="active_sub_cls"
            (click)="exceptionMenu('vendor', 'exc')"
            >Vendor</a
          >
          <a
            [routerLink]="'ExceptionManagement/Service_ExceptionManagement'"
            routerLinkActive="active_sub_cls"
            (click)="exceptionMenu('service', 'exc')"
            >Service</a
          >
          <a
            [routerLink]="'ExceptionManagement/approvalPending'"
            routerLinkActive="active_sub_cls"
            (click)="exceptionMenu('service', 'exc')"
            >Approval Pending</a
          >
        </div>
      </details>

      <span *ngIf="!(vendorInvoiceAccess && serviceInvoiceAccess)"
        >Exceptions</span
      >
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'Create_GRN_inv_list'"
      routerLinkActive="active_cls"
      *ngIf="GRNCreationAccess && GRNPageAccess && vendorInvoiceAccess"
    >
      Create GRN
    </div>
    <div
      class="nav_Link f-14"
      [routerLink]=" vendorInvoiceAccess ? 'invoice/allInvoices':'invoice/ServiceInvoices'"
      (click)="doc_status_route()"
      [ngClass]="{
        active_cls: router.url.includes('invoice'),
        cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
      }"
      routerLinkActive="active_cls"
      *ngIf="show_document_status"
    >
      Document Status
    </div>
    <div
      class="nav_Link f-14"
      (click)="approvalDropdown()"
      [ngClass]="{
        active_cls: router.url.includes('approvals'),
        cenomiNavlink: ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
      }"
      *ngIf="
        financeapproveDisplayBoolean && vendorInvoiceAccess && approveBoolean
      "
    >
      <span
        *ngIf="
          !(
            vendorInvoiceAccess &&
            serviceInvoiceAccess &&
            serviceApprovalsEnabled
          )
        "
        >Approvals</span
      >
      <details
        *ngIf="
          vendorInvoiceAccess && serviceInvoiceAccess && serviceApprovalsEnabled
        "
      >
        <summary>Approvals</summary>
        <div class="d-flex flex-column">
          <a
            [routerLink]="'approvals'"
            routerLinkActive="active_sub_cls"
            (click)="exceptionMenu('vendor', 'apr')"
            >Vendor</a
          >
          <a
            [routerLink]="'approvals/ServiceInvoices'"
            routerLinkActive="active_sub_cls"
            (click)="exceptionMenu('service', 'apr')"
            >Service</a
          >
        </div>
      </details>
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'GRN_approvals'"
      routerLinkActive="active_cls"
      *ngIf="GRNApprovalAccess"
    >
      GRN Approvals
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'documentSummary'"
      routerLinkActive="active_sub_cls"
      (click)="onMenuChange('Summary')"
    >
      Summary
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="supplier_route"
      routerLinkActive="active_sub_cls"
      (click)="onMenuChange(supplier_names)"
      *ngIf="vendor_SP_PageAccess"
    >
      {{ supplier_names }}
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
        ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'roles'"
      routerLinkActive="active_sub_cls"
      (click)="onMenuChange('Roles')"
      *ngIf="addUsersBoolean"
    >
      Roles
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
      ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'ERP_approvals'"
      routerLinkActive="active_sub_cls"
      (click)="onMenuChange('ERP Approvals')"
      *ngIf="addUsersBoolean"
    >
      ERP Approvals
    </div>
    <div
      class="nav_Link f-14"
      [ngClass]="
      ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      [routerLink]="'bulkUploadService'"
      routerLinkActive="active_sub_cls"
      (click)="onMenuChange('Service Bulk upload/ Batch trigger')"
      *ngIf="serviceInvoiceAccess"
    >
      Service Bulk upload/ Batch trigger
    </div>
    
    <div
      class="nav_Link f-14"
      [ngClass]="
      ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      (click)="openDialog()"
      *ngIf="isMobile"
    >
      Change Password
    </div>
    <!-- <a routerLink="profile">Profile</a> -->

    <div
      class="nav_Link f-14"
      [ngClass]="
      ['Cenomi','AGI'].includes(dataStoreService?.configData?.client_name)
          ? 'cenomiNavlink'
          : ''
      "
      (click)="confirm_pop()"
      *ngIf="isMobile"
    >
      Logout
    </div>
  </div>

  <section id="body_content">
    <router-outlet></router-outlet>
  </section>

  <footer class="f-12">
    <!-- Copyrights@2024 -->
    <div>
      <a href="static-content/DPA" target="_blank">{{isMobile? 'DPA':'Data Processing Agreement (DPA)'}}</a> | 
      <a href="static-content/privacy-policy" target="_blank">Privacy policy</a> | 
      <a href="mailto:help.serina@datasemantics.co">Help</a>
    </div>
  </footer>
</div>

<!-- Cnfirmation Dialog -->
<!-- <p-dialog
  header=" "
  [(visible)]="displayResponsivepopup"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '30vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div class="text-center">
    <div>
      <i
        class="pi pi-exclamation-triangle"
        style="font-size: 4rem; color: red"
      ></i>
    </div>
    <div class="deleteDivText">
      {{ BtnText }}
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center mb-3">
      <button class="btn btnVender bg-btn-success mr-3" (click)="logout()">
        Yes
      </button>
      <button
        class="btn btnVender bg-btn-cancel"
        (click)="displayResponsivepopup = false"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</p-dialog> -->

<p-toast></p-toast>
