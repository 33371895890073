<div class="container-fluid" style="overflow: hidden; max-height: 100vh">
  <div class="wild_search_all mt-2">
    <div class="filter_box">
      <label for="uni" class="f-12">
        Search Universal <br />
        <input
          [(ngModel)]="searchText"
          type="text"
          id="uni"
          class="textFilter f-13"
          [placeholder]="search_placeholder"
          (keyup)="universalSearch(searchText)"
        />
      </label>
    </div>
    <!-- <div class="calender_div">
        <p-calendar
          #datePicker
          [(ngModel)]="rangeDates"
          selectionMode="range"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder=""
          [readonlyInput]="true"
          [showIcon]="true"
          showButtonBar="true"
          (onClearClick)="clearDates()"
          (onSelect)="filterByDate(rangeDates)"
          inputId="range"
        ></p-calendar>
      </div> -->
  </div>
  <div class="pt-1 mt-2">
    <div *ngIf="!router.url.includes('ServiceInvoices')">
      <app-exception-table
        [invoiceColumns]="ApprovedColumn"
        [columnsToDisplay]="approvedColumnField"
        [showPaginatorAllInvoice]="showPaginatorApproved"
        [ColumnLength]="ColumnLengthVendor"
        [columnsData]="approvedData"
        [searchText]="searchText"
        [pageId]="'apr_v'"
        [pageNumber]="pageNumber"
        (paginationEvent)="paginate($event)"
      >
      </app-exception-table>
    </div>
    <div *ngIf="router.url.includes('ServiceInvoices')">
      <app-exception-table
        [invoiceColumns]="ApprovedColumnSP"
        [columnsToDisplay]="approvedColumnFieldSP"
        [showPaginatorAllInvoice]="showPaginatorApprovedSP"
        [ColumnLength]="ColumnLengthSP"
        [columnsData]="approvedDataSP"
        [searchText]="searchText"
        [pageId]="'apr_s'"
        [pageNumber]="pageNumber"
        (paginationEvent)="paginate($event)"
      >
      </app-exception-table>
    </div>

    <ngx-spinner
      bdColor="rgba(251, 251, 251, 0.8)"
      size="medium"
      color="#070900"
      [fullScreen]="false"
      type="ball-spin-clockwise"
    >
      <p style="color: rgb(0, 0, 0)"></p>
    </ngx-spinner>
  </div>
</div>
