<!-- <div class="d-flex justify-content-between"> -->
  <!-- <h6 *ngIf="!dashboardViewBoolean" class="headh6">{{ heading }}</h6> -->
  <!-- filters -->
  <div class="filters d-flex pt-2">
    <!-- universal search -->
    <!-- <div class="filter">
        <label for="uni" class="f-12"> Search Universal <br>
          <input [(ngModel)]="searchText" type="text" id="uni" class="textFilter f-13" placeholder="Ex: By Vendor, By PO" (keyup)="universalSearch(searchText)">
        </label>
      </div> -->
    <div class="wild_search_all">
      <div class="filter_box">
        <label for="uni" class="f-12 mb-0">
          Search Universal
        </label> 
          <input
            [(ngModel)]="searchText"
            type="text"
            id="uni"
            class="textFilter f-13"
            [placeholder]="search_placeholder"
            (keyup)="universalSearch(searchText)"
          />
      </div>
      <div class="calender_div">
        <p-calendar
          #datePicker
          [(ngModel)]="rangeDates"
          selectionMode="range"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder=""
          [readonlyInput]="true"
          [showIcon]="true"
          showButtonBar="true"
          (onClearClick)="clearDates()"
          (onSelect)="filterByDate(rangeDates)"
          inputId="range"
        ></p-calendar>
      </div>
    </div>

    <!-- search by VendorName -->
    <div class="filter status" *ngIf="heading.includes('Vendor')">
        <label class="f-12 png_label"> Search by PO Requestor </label>
        <p-autoComplete
        (onSelect)="onSelectVendor($event)"
        placeholder="Ex: abc@xyz.com"
        [suggestions]="filteredVendor"
        (completeMethod)="filterByVendorName($event)"
        field="VendorName"
        [dropdown]="true"
      >
        <ng-template let-vendor pTemplate="item">
          <div class="">
            <div class="f-12">{{ vendor.VendorName }}</div>
          </div>
        </ng-template>
      </p-autoComplete>
      </div>

    <!-- search by Status -->
    <div class="filter status" *ngIf="!router.url.includes('approvalPending')">
      <label class="f-12 png_label"> Search by Status </label>
      <p-autoComplete
        (onSelect)="onSelectStatus($event)"
        placeholder="Please Select Status"
        [suggestions]="filteredStatusList"
        (completeMethod)="filterByStatus($event)"
        field="name"
        [dropdown]="true"
        [(ngModel)]="selected_status_obj"
      >
        <ng-template let-status pTemplate="item">
          <div class="">
            <div class="f-12">{{ status.name }}</div>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>

    <div *ngIf="!isMobile" class="filter status export">
      <button class="secondary_btn f-18" (click)="exportExcel()">
        <i class="fa fa-download"></i>
      </button>
    </div>
  </div>
<!-- </div> -->

<!-- <ul class="nav nav-tabs">
        <li class="nav-item mr-1">
          <a
            class="nav-link"
            [class.active-tab]="viewType == 'normal'"
            (click)="chooseEditedpageTab('normal')"
            >Exceptions({{ dataLength }})</a
          >
        </li>
        <li class="nav-item mr-1" *ngIf="isVendorBoolean && !invoceDoctype">
          <a
            class="nav-link"
            [class.active-tab]="viewType == 'PODoc'"
            (click)="chooseEditedpageTab('PODoc')"
            >Exception PO's({{ dataLength }})</a
          >
        </li>
        <li class="nav-item ml-1" *ngIf="isVendorBoolean && this.apprveBool && invoceDoctype && portalName == 'customer'">
          <a
            class="nav-link"
            [class.active-tab]="viewType == 'editApproveBatch'"
            (click)="chooseEditedpageTab('editApproveBatch')"
            >Approval Pending({{ dataLengthAdmin }})</a
          >
        </li>
      </ul>
      <div style="position: relative;">
      <div class="positionCreateUpload">
        <button class="btnUpload mr-2" (click)="exportExcel()">
          <i class="fa fa-download" aria-hidden="true"></i>
        </button>
      </div>
      <div
          class="filter_input"
        >
          <p-calendar
            [(ngModel)]="rangeDates"
            selectionMode="range"
            [minDate]="minDate"
            [maxDate]="maxDate"
            placeholder="Select dates to Filter"
            [readonlyInput]="true"
            [showIcon]="true"
            showButtonBar="true"
            (onClearClick)="clearDates()"
            inputId="range"
          ></p-calendar>
          <button
            class="btnUpload filter_btn_m z_index"
            (click)="filterByDate(rangeDates)"
          >
          <i class="fa fa-filter" aria-hidden="true"></i>
          </button>
        </div>
    </div> -->

<div class="pt-2" [ngSwitch]="viewType">
  <!-- All Tab -->
  <div *ngSwitchCase="'normal'">
    <app-exception-table
      [invoiceColumns]="ColumnsForBatch"
      [columnsToDisplay]="columnsToDisplay"
      [showPaginatorAllInvoice]="showPaginatorAllInvoice"
      [ColumnLength]="batchProcessColumnLength"
      (searchInvoiceData)="searchInvoiceDataV($event)"
      [columnsData]="columnsData"
      [searchText]="searchText"
      [pageId]="'exc_v'"
      [pageNumber]="pageNumber"
      (filterDataEmit)="filterEmit($event)"
      (paginationEvent) = "paginate($event)"
    >
    </app-exception-table>
  </div>

  <ngx-spinner
    bdColor="rgba(251, 251, 251, 0.8)"
    size="medium"
    color="#070900"
    [fullScreen]="false"
    type="ball-spin-clockwise"
  >
    <p style="color: rgb(0, 0, 0)"></p>
  </ngx-spinner>
</div>
<p-toast></p-toast>
