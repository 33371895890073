<div class="tableDatDiv">

    <div class="filter_icon" (click)="openFilterDialog($event)">
      <mat-icon class="placement" svgIcon="filter_ic"></mat-icon>
    </div>
    <div class="d-flex justify-content-around f-wrap bg-w tableDtDiv" >
      <ng-container *ngFor="let obj of cardsArr">
        <div class="card-div d-flex d_flx_row" (click)="choosepageTab(obj.name)">
          <div class="img-wrapper">
            <mat-icon class="placement" [svgIcon]="obj.image"></mat-icon>
          </div>
          <div>
            <div class="f-15 primary_clr">{{ obj.count }}</div>
            <div class="f-12">{{ obj.title}}</div>
          </div>
        </div>
      </ng-container>
    </div>
  
    <div class="mt-2" [ngSwitch]="tabName">
      <!-- Total invoices Tab -->
      <div *ngSwitchCase="'Total'">
        <app-exception-table
          [invoiceColumns]="columnsForTotal"
          [columnsToDisplay]="totalColumnField"
          [showPaginatorAllInvoice]="showPaginatortotal"
          [ColumnLength]="ColumnLengthtotal"
          [columnsData]="totalTableData"
        >
        </app-exception-table>
      </div>
  
      <!-- UnderProcess invoices Tab -->
      <div *ngSwitchCase="'UnderProcess'">
        <app-exception-table
          [invoiceColumns]="columnsForUnderProcess"
          [columnsToDisplay]="UnderProcessColumnField"
          [showPaginatorAllInvoice]="showPaginatorUnderProcess"
          [ColumnLength]="ColumnLengthUnderProcess"
          [columnsData]="UnderProcessTableData"
        >
        </app-exception-table>
      </div>
  
      <!-- Posted invoices Tab -->
      <div *ngSwitchCase="'Posted'">
        <app-exception-table
          [invoiceColumns]="columnsForPosted"
          [columnsToDisplay]="PostedColumnField"
          [showPaginatorAllInvoice]="showPaginatorPosted"
          [ColumnLength]="ColumnLengthPosted"
          [columnsData]="PostedTableData"
        >
        </app-exception-table>
        <!-- <app-table
            [tableData]="CollectionsTableData"
            [invoiceColumns]="columnsForCollections"
            [showPaginator]="showPaginatorCollections"
            [columnsToFilter]="CollectionsColumnField"
            [columnLength]="ColumnLengthCollections"
        >
        </app-table> -->
      </div>
  
      <!-- Collections invoices Tab -->
      <div *ngSwitchCase="'Collections'">
        <app-exception-table
          [invoiceColumns]="columnsForCollections"
          [columnsToDisplay]="CollectionsColumnField"
          [showPaginatorAllInvoice]="showPaginatorCollections"
          [ColumnLength]="ColumnLengthCollections"
          [columnsData]="CollectionsTableData"
        >
        </app-exception-table>
      </div>

      <!-- Rejected invoices Tab -->
      <div *ngSwitchCase="'Rejected'">

        <app-exception-table
          [invoiceColumns]="columnsForRejected"
          [columnsToDisplay]="RejectedColumnField"
          [showPaginatorAllInvoice]="showPaginatorRejected"
          [ColumnLength]="ColumnLengthRejected"
          [columnsData]="RejectedTableData"
        >
        </app-exception-table>
      </div>
      <ngx-spinner
        bdColor="rgba(251, 251, 251, 0.8)"
        size="medium"
        color="#070900"
        [fullScreen]="false"
        type="ball-spin-clockwise"
      >
        <p style="color: rgb(0, 0, 0)"></p>
      </ngx-spinner>
    </div>

    <dialog #process_dialog>
      <div class="d-flex justify-content-between mb-2">
        <h6 class="f-14">Please select the option to filter</h6>
        <div class="f-14 pointer" (click)="closeDialog()"> <i class="fa fa-close"></i></div>
      </div>
      <div class="inputBox">
        <label class="f-12 shade_color">Start & End Date</label><br>
        <p-calendar
          [(ngModel)]="rangeDates"
          selectionMode="range"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder="Select dates to Filter"
          [readonlyInput]="true"
          [showIcon]="true"
          showButtonBar="true"
          inputId="range"
          (onClearClick)="clearDates()"
        ></p-calendar>
      </div>
      
        <div class="d-flex justify-content-end footer mt-3">
          <button class="btnUpload" (click)="filterByDate(rangeDates)">Filter</button>
        </div>
    </dialog>
  </div>
