<h6 class="headh6 text_color f-14">Service invoice batch trigger</h6>


  <div class="fileUploadDiv p-2 m-2">
    <div class="centerSelection centerCss">
      <div class="d-flex">
        <div class="selectFilter mr-2">
          <label class="f-12 z_index mb-0">Select Entity</label><br />
          <select class="form-control accountSelect f-14" (change)="selectEntity($event.target.value)">
            <option value="" style="font-weight: 500;">Select Entity</option>
            <option *ngFor="let entity of entity" [value]="entity.idEntity" >
              {{ entity.EntityName }}
            </option>
          </select>
        </div>
        <button *ngIf="erpTriggerBoolean" class="primary_btn f-12 mt-2" (click)="triggerBatch()" >Start Batch</button>
      </div>

    </div>
  </div>

  <div>
    <!-- <app-table
          [tableData]="totalTableData"
          [invoiceColumns]="columnsForTotal"
          [showPaginator]="showPaginatortotal"
          [columnsToFilter]="totalColumnField"
          [columnLength]="ColumnLengthtotal"
        >
        </app-table> -->
        <app-exception-table
        [invoiceColumns]="columnsForTotal"
        [columnsToDisplay]="totalColumnField"
        [showPaginatorAllInvoice]="showPaginatortotal"
        [ColumnLength]="ColumnLengthtotal"
        [columnsData]="totalTableData"
      >
      </app-exception-table>
  </div>
<p-toast></p-toast>

