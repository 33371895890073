<div class="container-fluid reginstration_fullpage">
    <div class="nameHead">
        Serina Plus
    </div>
    <!-- <div class="nameHead">
        <img alt="Serina logo" src="assets/Serina Assets/new_theme/logo.png" class="ser-logo">
    </div> -->
    <div *ngIf="linkActiveBoolean" class="registrationDiv">
      <div class="title">Registration Form</div>
        <form [formGroup]="registrationForm" (ngSubmit)="savePasswordforNewuser()">
            <!-- <div>
                <label for="userName">User Name</label>
                <input type="text" id="userName" formControlName ="userName" class="form-control inputForm">
            </div> -->
            <div class="form-cntnt">
              <div class="add-subject disable">
                  <label class="f-12 mb-0 sub">User Name</label>
                  <input type="text" formControlName="userName" class="form-control mb-1 inputHeight inputbox" />
                </div>
                <div class="add-subject disable">
                  <label class="f-12 mb-0 sub">First Name</label>
                  <input type="text" formControlName="firstName" class="form-control mb-1 inputHeight inputbox" />
                </div>
                <div class="add-subject disable">
                  <label class="f-12 mb-0 sub">Last Name</label>
                  <input type="text" formControlName="lastName" class="form-control mb-1 inputHeight inputbox" />
                </div>
                <div class="add-subject">
                  <label class="f-12 mb-0 sub">Set Password</label>
                  <div class="input-group">
                    <input [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="mpassword"
                    formControlName="password" class="form-control mb-1 inputHeight inputbox" (ngModelChange)="passwordValidation()" />
                    <div class="input-group-append" *ngIf="mpassword">
                      <span class="input-group-text">
                        <i class="fa" [ngClass]="{ 'fa-eye-slash': !fieldTextType, 'fa-eye': fieldTextType }" (click)="toggleFieldTextType()"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div *ngIf="(registrationForm.get('password').touched || registrationForm.get('password').dirty) 
                && (!lengthbool || !specialCharbool || !upperCharbool || !lowerCharbool || !numberbool) 
                ">
                  <!-- <div [ngClass]="{
                    'error-text': !registrationForm.get('password').value, 
                    'success-text': registrationForm.get('password').value
                    }">
                    Password is required
                  </div> -->
            
                  <div 
                    [ngClass]="{
                      'error-text': !lengthbool, 
                      'success-text': lengthbool
                      }">
                        Password must be at least 8 characters
                  </div>
                  <div 
                    [ngClass]="{
                      'error-text': !specialCharbool, 
                      'success-text': specialCharbool
                      }">
                        Password must Contain Special character
                  </div>
                  <div 
                    [ngClass]="{
                      'error-text': !upperCharbool, 
                      'success-text': upperCharbool
                      }">
                        Password must Contain uppercase character
                  </div>
                  <div 
                    [ngClass]="{
                      'error-text': !lowerCharbool, 
                      'success-text': lowerCharbool
                      }">
                        Password must Contain lowercase character
                  </div>
                  <div 
                    [ngClass]="{
                      'error-text': !numberbool, 
                      'success-text': numberbool
                      }">
                        Password must Contain Number
                  </div>
                </div>
                <div class="add-subject">
                    <label class="f-12 mb-0 sub">Confirm Password</label>
                    <div class="input-group">
                        <input type="password" id="confirmPass" formControlName ="reEnterPassword" 
                        (focus)="confirmPasswordFocus = true" (blur)="confirmPasswordFocus = false"
                        (keyup)="confirmPassword($event.target.value)" class="form-control mb-1 inputHeight inputbox">
                    </div>

                </div>
                <div class="alert alert-danger f-12" *ngIf="errorDivBoolean">
                  Please enter the same password.
                </div>
                <div class="f-12">
                  Note: Please create your password and click submit.
                </div>
              </div>
            <div class="butn-cntnr">
                    <button class="primary_btn f-12 btn-block" type="submit" 
                     [disabled]="!registrationForm.valid || errorDivBoolean"
                    [ngClass]="{'dull-mode': !registrationForm.valid || errorDivBoolean }">Submit</button>
            </div>
            
        </form>
    </div>
    <div *ngIf="!linkActiveBoolean" class="registrationDiv">
        <div>
        Activation Link got Expired!.
        </div>
        <div>
          <div class="add-subject">
            <label class="f-12 mb-0 sub">Enter email id to get Activation link</label>
            <input type="email" class="form-control mb-1 inputHeight inputbox" [(ngModel)]="emailId" placeholder="Enter your Email">
          </div>
          <div class="butn-cntnr">
            <div class="btn">
              <button class="primary_btn f-12" (click)="resendActivationLink()">Get Activation Link</button>
            </div>
            
          </div>
        </div>
    </div>
  </div>
<p-toast></p-toast>