<div class="headh6"></div>
<div class="d-flex" style="gap:14px">
  <div class="column1 bgColumns">
    <div class="navDiv">
      <ul class="nav nav-tabs tabBox pl-2">
        <li>
          <a
            class="nav-link"
            [class.active]="viewType == 'Accepted'"
            (click)="viewType = 'Accepted'"
            >Accepted</a
          >
        </li>
        <li>
          <a
            class="nav-link"
            [class.active]="viewType == 'In Queue'"
            (click)="viewType = 'In Queue'"
            >In Queue</a
          >
        </li>
  
      </ul>
    </div>
    <div [ngSwitch]="viewType" >
      <div *ngSwitchCase="'Accepted'" class="d-flex flex-wrap" style="gap: 10px;">
        <ng-container *ngFor="let vendor of queueVendorList">
          <div class="cardErp cardHover">
            <div class="d-flex justify-content-between">
              <div class="shortName f-16 mr-2">{{vendor.shortName}}</div>
              <div class="f-14">{{vendor.vendorName}}</div>
            </div>
            <div class="f-10 mt-1 font_weight600">{{vendor.emailId}}</div>
            <div class="d-flex justify-content-between mt-2">
              <div>
                <label class="f-10 shade_color">Telephone / Fax No</label>
                <div class="f-12">{{vendor.phone}}</div>
              </div>
              <div>
                <label class="f-10 shade_color">General Manager</label>
                <div class="f-12">{{vendor.manager}}</div>
              </div>
            </div>

            <div class="d-flex justify-content-between mt-3 Card_footer">
              <button class="secondary_btn f-12">Need More</button>
              <button class="primary_btn f-12">Approve</button>
            </div>
          </div>
        </ng-container>

      </div>

      <div *ngSwitchCase="'In Queue'">
        In Queue
      </div>
    </div>
  </div>

  <div class="column2 bgColumns">
    <h6 class="f-16">Health Choice General Trading</h6>
    <div class="f-14">Basic Details</div>
    <div class="BoxDiv">
      <div *ngFor="let box of userDetails">
        <label class="f-12 shade_color mb-0">{{ box.label }}</label><br>
        <input type="text" [name]="box.name" [(ngModel)]="box.value" class="f-14"> 
      </div>
    </div>

    <div class="f-14 mt-2">Bank Details Details</div>
    <div class="BoxDiv">
      <div *ngFor="let box of BankDetails" >
        <label class="f-12 shade_color mb-0">{{ box.label }}</label><br>
        <input type="text" [name]="box.name" [(ngModel)]="box.value" class="f-14"> 
      </div>
    </div>
  </div>
</div>