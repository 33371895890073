<div class="filter_icon d-flex">
  <div class="f-12 p_t5" [ngClass]="client_name == 'AGI' ? 'mr-3': 'mr-5'">Current filter:   {{ rangeDates[0] | date: 'MMM d, yyyy' }} - {{ rangeDates[1] | date: 'MMM d, yyyy' }}</div>
  <div class="f-12 mr-5" *ngIf="client_name == 'AGI'"><a [href]="agi_p_link" target="_blank"><img src="../../../../../assets/Serina Assets/new_theme/PowerApps_scalable.svg" alt="Power apps" height="30"> Power apps</a></div>
</div>
<div class="filter_icon" (click)="openFilterDialog($event)">
  <mat-icon class="placement" svgIcon="filter_ic"></mat-icon>
</div>
  <div class="d-flex f-wrap bg-w justify-content-around">
    <ng-container *ngFor="let obj of cardsArr">
      <div class="card-div d-flex d_flx_row">
        <div class="img-wrapper">
          <mat-icon class="placement" [svgIcon]="obj.image"></mat-icon>
        </div>
        <div>
          <div class="f-15 primary_clr">{{ obj.count }}</div>
          <div class="f-12">{{ obj.title}}</div>
        </div>
      </div>
    </ng-container> 
  </div>
<!-- <div class="charts_height">
  <div class="d-flex justify-content-between mt-2" style="position: relative">
    <div class="chart_title f-12">
      Processed vs Downloaded per Service Provider
    </div>
    <div id="stack_chart" class="chart_div"></div>
    <div class="chart_title f-12" style="left: 52%">Total Processed Value</div>
    <div id="column_chart" class="chart_div"></div>
  </div>
  <div class="d-flex justify-content-between mt-2" style="position: relative">
    <div class="chart_title f-12">Pending Invoices by Amount</div>
    <div id="column_chart1" class="chart_div"></div>
    <div class="chart_title f-12" style="left: 52%">
      Overall Invoice Processed vs Downloaded
    </div>
    <div id="pie_chart" class="chart_div"></div>
  </div>
</div> -->

<div class="charts_height">
  <div class="d-flex justify-content-between d-column" style="position: relative">
    <div class="charts_box w-49">
      <div class="chart_title f-12">Invoice Count by Entity</div>
      <button *ngIf="invoiceByEntityChartdata?.length>0" class="btnUpload btnD pos_ab_20" (click)="downloadReport()">
        <i class="fa fa-download" aria-hidden="true"></i>
      </button>
  
      <div class="chart_div1 d-flex ent_count">
        <ng-container *ngFor="let data of invoiceByEntityChartdata">
          <div class="ent_card">
            <div class="f-12 shade_color">{{ data.EntityName }}</div>
            <div class="countSb_div">
              <div class="d-flex count_div">
                <div class="f-18">{{ data.count }} <span class="f-10 line_text shade_color">invoice</span></div>
                <div class="f-18 shade_color" >|</div>
                <div class="f-18">{{ data.pagecount }}  <span class="f-10 line_text shade_color">Pages</span></div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
  
      <div class="hidden">
          
      </div>
    </div>

    <!-- <div class="chart_title f-12" style="left: 52%">
      Pages Count by Entity
    </div>
    <div class="chart_div">
      <div [ngStyle]="{'visibility': !noDataSourcePagesEntityboolean ? 'visible':'hidden'}" id="column_chart_entity_pages"></div>
      <div [ngStyle]="{'visibility': noDataSourcePagesEntityboolean ? 'visible':'hidden'}"  class="f-13 center-card-text">
        No Data Available
      </div>
    </div> -->
    <div class="chart_title f-12 l-52">
      Overall Invoice Processed vs Downloaded
    </div>
    <div class="chart_div">
      <div [ngStyle]="{'visibility': !noDataOverallboolean ? 'visible':'hidden'}" id="pie_chart" ></div>
      <div [ngStyle]="{'visibility': noDataOverallboolean ? 'visible':'hidden'}" class="f-13 center-card-text">
        No Data Available
      </div>
    </div>
    
  </div>
  <div class="d-flex justify-content-between d-column mt-2" style="position: relative">
    <div class="chart_title f-12">Processed vs Downloaded per Service Provider</div>
    <div  class="chart_div">
      <div [ngStyle]="{'visibility':!noDataCountboolean ? 'visible':'hidden'}" id="stack_chart" >
      </div>
      <div [ngStyle]="{'visibility':noDataCountboolean ? 'visible':'hidden'}" class="f-13 center-card-text">
        No Data Available
      </div>
    </div>
    <div class="chart_title f-12 l-52">
      Total Processed Value
    </div>
    <div class="chart_div">
      <div [ngStyle]="{'visibility':!noDataProcessboolean ? 'visible':'hidden'}" id="column_chart"></div>
      <div [ngStyle]="{'visibility': noDataProcessboolean ? 'visible':'hidden'}"  class="f-13 center-card-text">
        No Data Available
      </div>
    </div>
    
  </div>
  <div class="d-flex justify-content-between d-column mt-2" style="position: relative">
    <div class="chart_title f-12">Pending Invoices by Amount</div>
    <div class="chart_div">
      <div [ngStyle]="{'visibility': !noDataPendingboolean ? 'visible':'hidden'}" id="column_chart1" ></div>
      <div [ngStyle]="{'visibility': noDataPendingboolean ? 'visible':'hidden'}" class="f-13 center-card-text">
        No Data Available
      </div>
    </div>
    

    
  </div>

</div>

<dialog #process_dialog>
  <div class="d-flex justify-content-between mb-2">
    <h6 class="f-14">Please select the option to filter</h6>
    <div class="f-14 pointer" (click)="closeDialog()"> <i class="fa fa-close"></i></div>
  </div>
  <div class="inputBox">
    <label class="f-12 shade_color">Start & End Date</label><br>
    <p-calendar
      #datePicker
      [(ngModel)]="rangeDates"
      selectionMode="range"
      [minDate]="minDate"
      [maxDate]="maxDate"
      placeholder="Select dates to Filter"
      [readonlyInput]="true"
      [showIcon]="true"
      showButtonBar="true"
      inputId="range"
      (onClearClick)="clearDates()"
      (onSelect) = "selectedDates(rangeDates)"
    ></p-calendar>
  </div>
    <div class="inputBox">
      <label class="f-12 shade_color">Select ServiceProvider</label><br>
      <select class="form-control f-12 font_weight" (change)="selectedService($event.target.value)">
        <option value="">Select</option>
        <option value="ALL" selected>ALL</option>
        <option *ngFor="let service of serviceData" [value]="service.ServiceProviderName">
          {{ service.ServiceProviderName }}
        </option>
      </select>
    </div>
  
    <div class="inputBox">
      <label class="f-12 shade_color">Select Entity</label><br>
      <select
      class="f-12 form-control font_weight"
      (change)="selectEntityFilter($event.target.value)"
    >
      <option value="">Select Entity</option>
      <option value="ALL" selected>ALL</option>
      <option *ngFor="let entity of entity" [value]="entity.idEntity">
        {{ entity.EntityName }}
      </option>
    </select>
    </div>

    <div class="d-flex justify-content-end footer mt-3">
      <button class="btnUpload" (click)="filterByDate(rangeDates)">Filter</button>
    </div>
</dialog>

<ngx-spinner
bdColor="rgba(251, 251, 251, 0.8)"
size="medium"
color="#070900"
[fullScreen]="false"
type="ball-spin-clockwise"
>
<p style="color: rgb(0, 0, 0)"></p>
</ngx-spinner>
