
<div
class="nav_pane_width1 d-flex justify-content-between flex-wrap inv_nav_container"
>
<ul class="nav nav-pills nav_sub">
  <li class="nav-item mr-1">
    <a
      class="nav-link f-14"
      [class.active-pill]="viewType == 'Process'"
      (click)="choosepageTab('Process')"
    >
      Process Reports</a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link f-14"
      [class.active-pill]="viewType == 'Exception'"
      (click)="choosepageTab('Exception')"
    >
      Exception Reports</a
    >
  </li>

  <!-- <li class="nav-item">
    <a
      class="nav-link ml-1 f-14"
      [class.active-pill]="viewType == 'emailException'"
      (click)="choosepageTab('emailException')"
    >
    Email Reports</a
    >
  </li> -->

  <li class="nav-item">
    <a
      class="nav-link ml-1 f-14"
      [class.active-pill]="viewType == 'onboarded'"
      (click)="choosepageTab('onboarded')"
    >
    Onboarded Reports</a
    >
  </li>
</ul>
</div>
<div class="d-flex justify-content-end mt-1">
  <div *ngIf=" viewType == 'onboarded'" class="mr-2"> <button class="btnUpload" (click)="downloadReport()"><span *ngIf="isDesktop">Download Report</span> <i *ngIf="!isDesktop" class="fa fa-download"></i></button></div>
  <div *ngIf="viewType == 'emailException'">
    <p-calendar
      [(ngModel)]="rangeDates"
      selectionMode="range"
      [minDate]="minDate"
      [maxDate]="maxDate"
      placeholder="Select dates to Filter"
      [readonlyInput]="true"
      [showIcon]="true"
      showButtonBar="true"
      inputId="range"
      (onClearClick)="clearDates()"
    ></p-calendar>

    <button class="btnUpload filter_btn_m" (click)="filterByDate(rangeDates)">Filter</button>
  </div>

  <div *ngIf=" viewType == 'onboarded'" class="onboarded mr-2">
    <p-calendar [(ngModel)]="selectDate" view="month" dateFormat="mm" [showIcon]="true" [yearNavigator]="false" [minDate]="minDate" 
    [maxDate]="maxDate" [yearRange]='displayYear' placeholder="Select dates to filter.." [readonlyInput]="false"
    inputId="monthpicker"></p-calendar>

    <button class="btnUpload filter_btn_m " (click)="applyMonthfilter(selectDate)"> Filter</button>
  </div>
</div>


<div  [ngSwitch]="viewType" class="mt_main">
  <!-- Process invoices Tab -->
  <div *ngSwitchCase="'Process'">
    <app-process-reports></app-process-reports>
  </div>

  <!-- Exception invoices Tab -->
  <div *ngSwitchCase="'Exception'" class="tableDaDiv">
    <app-exception-reports></app-exception-reports>
  </div>

  <!-- Exception invoices Tab -->
  <div *ngSwitchCase="'emailException'" class="tableataDiv">
    <app-table
      [tableData]="exceptionTableData"
      [invoiceColumns]="columnsForException"
      [showPaginator]="showPaginatorException"
      [columnsToFilter]="exceptionColumnField"
      [columnLength]="ColumnLengthException"
    >
    </app-table>
  </div>

  <!-- Onboarding Data Tab -->
  <div *ngSwitchCase="'onboarded'" class="tableataDiv">
    <!-- <app-table
      [tableData]="onboardTableData"
      [invoiceColumns]="columnsForonboard"
      [showPaginator]="showPaginatoronboard"
      [columnsToFilter]="onboardColumnField"
      [columnLength]="ColumnLengthonboard"
    >
    </app-table> -->
      <app-exception-table
      [invoiceColumns]="columnsForonboard"
      [columnsToDisplay]="onboardColumnField"
      [showPaginatorAllInvoice]="showPaginatoronboard"
      [ColumnLength]="ColumnLengthonboard"
      [columnsData]="onboardTableData"
    >
    </app-exception-table>
  </div>

  
</div>
