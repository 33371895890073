
<div class="filter_icon" (click)="openFilterDialog($event)">
  <mat-icon class="placement" svgIcon="filter_ic"></mat-icon>
</div>
<div class="d-flex justify-content-around f-wrap bg-w tableDtDiv" >
  <ng-container *ngFor="let obj of cardsArr">
    <div class="card-div d-flex d_flx_row" (click)="choosepageTab(obj.name)">
      <div class="img-wrapper">
        <mat-icon class="placement" [svgIcon]="obj.image"></mat-icon>
      </div>
      <div>
        <div class="f-15 primary_clr">{{ obj.count }}</div>
        <div class="f-12">{{ obj.title}}</div>
      </div>
    </div>
  </ng-container>
</div>

<div class="pt-1" [ngSwitch]="tabName">
  <!-- Total invoices Tab -->
  <div *ngSwitchCase="'Total'">
    <app-exception-table
    [invoiceColumns]="columnsForTotal"
    [columnsToDisplay]="totalColumnField"
    [showPaginatorAllInvoice]="showPaginatortotal"
    [ColumnLength]="ColumnLengthtotal"
    [columnsData]="totalTableData"
  >
  </app-exception-table>
  </div>

  <!-- OCR invoices Tab -->
  <div *ngSwitchCase="'OCR'">
    <app-exception-table
    [invoiceColumns]="columnsForOCR"
    [columnsToDisplay]="OCRColumnField"
    [showPaginatorAllInvoice]="showPaginatorOCR"
    [ColumnLength]="ColumnLengthOCR"
    [columnsData]="OCRTableData"
  >
  </app-exception-table>
  </div>

  <!-- Batch invoices Tab -->
  <div *ngSwitchCase="'Batch'">
    <app-exception-table
      [invoiceColumns]="columnsForbatch"
      [columnsToDisplay]="batchColumnField"
      [showPaginatorAllInvoice]="showPaginatorbatch"
      [ColumnLength]="ColumnLengthbatch"
      [columnsData]="batchTableData"
    >
    </app-exception-table>
  </div>

  <!-- ERP invoices Tab -->
  <div *ngSwitchCase="'ERP'">
    <app-exception-table
      [invoiceColumns]="columnsForERP"
      [columnsToDisplay]="OCRColumnField"
      [showPaginatorAllInvoice]="showPaginatorERP"
      [ColumnLength]="ColumnLengthERP"
      [columnsData]="ERPTableData"
    >
    </app-exception-table>
  </div>
  <ngx-spinner
    bdColor="rgba(251, 251, 251, 0.8)"
    size="medium"
    color="#070900"
    [fullScreen]="false"
    type="ball-spin-clockwise"
  >
    <p style="color: rgb(0, 0, 0)"></p>
  </ngx-spinner>
</div>

<dialog #process_dialog>
  <div class="d-flex justify-content-between mb-2">
    <h6 class="f-14">Please select the option to filter</h6>
    <div class="f-14 pointer" (click)="closeDialog()"> <i class="fa fa-close"></i></div>
  </div>
  <div class="inputBox">
    <label class="f-12 shade_color">Start & End Date</label><br>
    <p-calendar
      #datePicker
      [(ngModel)]="rangeDates"
      selectionMode="range"
      [minDate]="minDate"
      [maxDate]="maxDate"
      placeholder="Select dates to Filter"
      [readonlyInput]="true"
      [showIcon]="true"
      showButtonBar="true"
      inputId="range"
      (onSelect) = "selectedDates(rangeDates)"
      (onClearClick)="clearDates()"
    ></p-calendar>
  </div>
  
    <div class="d-flex justify-content-end footer mt-3">
      <button class="btnUpload" (click)="filterByDate(rangeDates)">Filter</button>
    </div>
</dialog>
